import { css } from "styled-components";

import colors from "constants/colors";
import media from "constants/media";

export const cssVariables = css`
    /*
     * Transitions
     */
    --animation-duration-simple: 100ms;

    --animation-duration-fast: 100ms;
    --animation-duration-default: 200ms;
    --animation-duration-long: 500ms;

    --animation-duration-cardExpand: 300ms;
    --animation-duration-cardCollapse: 250ms;

    --animation-easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
    --animation-easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
    --animation-easing-accelerate: cubic-bezier(0.4, 0, 1, 1);

    /*
     * Borders
     */
    --border-radius-small: 2px;
    --border-radius-default: 3px;
    --border-radius-medium: 5px;
    --border-radius-card: 10px;

    /*
     * Colors
     */
    --color-blue-300: ${colors.blue300};
    --color-blue-400: ${colors.blue400};
    --color-blue-500: ${colors.blue500};
    --color-blue-600: ${colors.blue600};
    --color-blue-700: ${colors.blue700};

    --color-peach-400: ${colors.peach400};
    --color-peach-500: ${colors.peach500};
    --color-peach-600: ${colors.peach600};
    --color-peach-700: ${colors.peach700};
    --color-peach: var(--color-peach-400);

    --color-gray-300: ${colors.gray300};
    --color-gray-400: ${colors.gray400};
    --color-gray-500: ${colors.gray500};
    --color-gray-600: ${colors.gray600};
    --color-gray-700: ${colors.gray700};

    --color-purple-200: ${colors.purple200};
    --color-purple-300: ${colors.purple300};
    --color-purple-400: ${colors.purple400};
    --color-purple-500: ${colors.purple500};

    --color-green-400: ${colors.green400};
    --color-green-500: ${colors.green500};
    --color-green: var(--color-green-500);

    --color-red-400: ${colors.red400};
    --color-red-500: ${colors.red500};
    --color-red-600: ${colors.red600};
    --color-red-700: ${colors.red700};
    --color-red: var(--color-red-400);

    --color-orange-500: ${colors.orange500};

    --color-black: ${colors.black};
    --color-white: ${colors.white};

    --color-primary: var(--color-blue-500);
    --color-secondary: var(--color-peach-400);
    --color-tertiary: var(--color-gray-300);

    /*
     * Shadows
     */
    --box-shadow-mobile: 0 2px 8px 0 rgba(13, 58, 112, 0.15);
    --box-shadow-desktop: 0 2px 8px 0 rgba(13, 58, 112, 0.15);
    --box-shadow-field: 0 3px 7px 0 rgba(49, 49, 62, 0.19);

    /*
     * Typography
     */
    --font-family-primary: "Inter", sans-serif;
    --font-family-secondary: "Lexend Deca", sans-serif;

    --font-weight-extra-light: 200;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;

    --font-size-body: 14px;

    --line-height-body: 30px;

    /*
     * Giftcard
     */
    --aspect-ratio-card: 37 / 24;

    ${media.mdUp} {
        /*
         * Borders
         */
        --border-radius-card: 20px;
    }

    ${media.lgUp} {
        /*
         * Borders
         */
        --border-radius-card: 40px;
    }
`;
