import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { ApolloProvider } from "@apollo/client/react/context";
import { resolveValue, toast, Toaster } from "react-hot-toast";

import MediaQueryProvider from "helpers/hooks/useMediaQueryContext";
import { useApollo } from "lib/apolloClient";

import GlobalStyle from "theme/global-styles";

import BaseHead from "components/shared/head/Base/Base";
import GoogleTagManager from "components/googleTagManager/GoogleTagManager";
import Script from "next/script";

const Notification = dynamic(() =>
    import("components/ui/notification/Notification")
);

export default function MyApp({ Component, pageProps }) {
    const apolloClient = useApollo(pageProps);
    const isChristmas =
        process.env.featureFlags.isChristmas || pageProps?.isChristmas;

    return (
        <>
            <BaseHead />
            <GlobalStyle
                isChristmas={isChristmas}
                privateSauna={pageProps?.privateSauna}
            />

            <MediaQueryProvider>
                <ApolloProvider client={apolloClient}>
                    <GoogleTagManager>
                        <Component {...pageProps} />
                    </GoogleTagManager>
                </ApolloProvider>
            </MediaQueryProvider>
            <Toaster position="bottom-right">
                {t => (
                    <Notification
                        type={t.type}
                        dismiss={() => toast.dismiss(t.id)}
                        message={resolveValue(t.message, t)}
                        duration={t.duration}
                        className={
                            t.visible ? "animate-enter" : "animate-leave"
                        }
                        {...t.ariaProps}
                    />
                )}
            </Toaster>
            <Script
                src="https://consent.cookiebot.com/uc.js"
                strategy="afterInteractive"
                id="Cookiebot"
                data-cbid="51a3f7bb-e7bf-42b4-8081-fcf8c434976b"
            />
        </>
    );
}

MyApp.defaultProps = {
    Component: undefined,
    pageProps: undefined,
    err: null
};

MyApp.propTypes = {
    Component: PropTypes.any,
    pageProps: PropTypes.any,
    err: PropTypes.any
};
