const colors = {
    white: "#ffffff",
    black: "#000000",
    blue300: "#00779d",
    blue400: "#209cc4",
    blue500: "#03b0e7",
    blue600: "#d6e1e6",
    blue700: "#e5f5fb",
    peach400: "#e8ad95",
    peach500: "#f7e5dd",
    peach600: "#f4edea",
    peach700: "#f9f5f4",
    gray300: "#232122",
    gray400: "#9e979b",
    gray500: "#a6959f",
    gray600: "#f4f4f4",
    gray700: "#f9fafc",
    purple200: "#3e3138",
    purple300: "#af008e",
    purple400: "#cc08a7",
    purple500: "#c51d63",
    green400: "#55a023",
    green500: "#a2dc50",
    red400: "#f02929",
    red500: "#f08c8c",
    red600: "#dfd2cd",
    red700: "#f9ecea",
    orange500: "#f07a29"
};

colors.primary = colors.blue500;
colors.secondary = colors.peach400;
colors.tertiary = colors.gray300;

colors.green = colors.green500;
colors.red = colors.red400;

export default colors;
