import { createGlobalStyle } from "styled-components";

import { ModalBase } from "components/ui/modal/Modal.styled";

import { cssVariables } from "./variables";

function getBackgroundColor(privateSauna, isChristmas) {
    let startColor = "var(--color-peach-500)";

    if (privateSauna) {
        startColor = "#fce0ec";
    }
    if (isChristmas || process.env.featureFlags.christmas) {
        startColor = "#f7dddd";
    }

    return `linear-gradient(180deg, ${startColor} 0.01%, var(--color-white) 90%)`;
}

export default createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    :root {
        ${cssVariables}
    }

    body {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow-x: hidden;

        color: var(--color-tertiary);
        font-family: var(--font-family-primary);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        background: ${({ privateSauna, isChristmas }) =>
            getBackgroundColor(privateSauna, isChristmas)} no-repeat;


        #__next {
            overflow: hidden;
        }
    }

    code {
        font-family: "source-code-pro", monospace;
    }

    p {
        font-size: var(--font-size-body);
        line-height: var(--line-height-body);
    }

    a, button {
        color: inherit;
        font-size: inherit;
        line-height: inherit;

        cursor: pointer;
    }

    a {
        color: var(--color-primary);
        text-decoration: none;

        transition: all var(--animation-duration-default) ease;

        &:hover {
            color: var(--color-secondary);
        }
    }

    input {
        border: none;
        outline: none;
        inset: 0;
    }

    textarea,
    button,
    input {
        font-family: var(--font-family-primary);
    }

    button {
        padding: 0;

        background: transparent;

        border: 0;
        outline: none;
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight: var(--font-weight-normal);
        font-family: var(--font-family-secondary);
    }

    /* Specific theme variables */
    ${ModalBase}
`;
