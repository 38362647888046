import PropTypes from "prop-types";
import { createContext, useContext, useMemo } from "react";
import useMedia from "use-media";
import { sizes } from "constants/media";

const mediaQueries = {
    sm: `(max-width: ${sizes.md - 1}px)`,
    md: `(min-width: ${sizes.md}px) and (max-width: ${sizes.lg - 1}px)`,
    xl: `(min-width: ${sizes.xl}px)`,
    lg: `(min-width: ${sizes.lg}px) and (max-width: ${sizes.xl - 1}px)`,
    desktop: `(min-width: ${sizes.lg}px)`
};

const MediaQueryContext = createContext(null);

export function useMediaQueryContext() {
    return useContext(MediaQueryContext);
}

function MediaQueryProvider({ children, defaultView }) {
    const queries = {
        sm: useMedia(mediaQueries.sm, defaultView === "mobile"),
        md: useMedia(mediaQueries.md, defaultView === "tablet"),
        lg: useMedia(mediaQueries.lg, defaultView === "desktop"),
        xl: useMedia(mediaQueries.xl, defaultView === "desktopLarge"),
        desktop: useMedia(mediaQueries.desktop, defaultView === "desktop")
    };

    const value = useMemo(
        () => ({
            mobileView: queries.sm,
            tabletView: queries.md,
            desktopView: queries.desktop,
            desktopOnly: queries.lg,
            desktopLargeView: queries.xl
        }),
        [queries.sm, queries.md, queries.lg, queries.desktop, queries.xl]
    );

    return (
        <MediaQueryContext.Provider value={value}>
            {children}
        </MediaQueryContext.Provider>
    );
}

MediaQueryProvider.propTypes = {
    children: PropTypes.node.isRequired,
    defaultView: PropTypes.oneOf([
        "mobile",
        "tablet",
        "desktop",
        "desktopLarge"
    ])
};

MediaQueryProvider.defaultProps = {
    defaultView: "mobile"
};

export default MediaQueryProvider;
