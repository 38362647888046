import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import * as gtm from "lib/gtag";
import Script from "next/script";

const handleRouteChange = url => {
    gtm.pageview(url);
};

function GoogleTagManager({ children }) {
    const router = useRouter();
    const [datalayerSet, setDatalayerSet] = useState(false);

    useEffect(() => {
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        window.dataLayer = window?.dataLayer || [];
        window?.dataLayer?.push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        setDatalayerSet(true);
    }, []);

    return (
        <>
            {datalayerSet && (
                <Script id="gtm" strategy="afterInteractive">
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'}); var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:''; j.async=true;j.src='https://load.sst.saunawellnesscadeaukaart.nl/xyqorrnw.js?st='+i+dl+''; f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','T63QJT');`}
                </Script>
            )}
            {children}
        </>
    );
}

GoogleTagManager.propTypes = {
    children: PropTypes.node.isRequired
};

export default GoogleTagManager;
